

import React from "react";
import '../../components/Layout/layout.css';
import LazyLoad from "react-lazyload";

const CustomYoutube = ({ children }) => {
  

  return (
    <div className="yt-video">
      <LazyLoad>
        <iframe
          width="100%"
          height="100%"
          src={children}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          id="player_1"
        ></iframe>
      </LazyLoad>
  
    </div>
  );
};

export default CustomYoutube;

